/*
Everything in this file is subject to change, and mostly handles "slow moving
data" that doesn't exist in the api yet.  Plan to eventually remove the majority
of this and rely soley on the api for information.
*/

export const brandLogoMap = {
"Arbor Mist": "https://res.cloudinary.com/brandhub/image/upload/v1611763920/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Arbor_Mist_ped7se.png",
"Black Box": "https://res.cloudinary.com/brandhub/image/upload/v1611763963/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Black_Box_gl8m1n.png",
"Clos Du Bois": "https://res.cloudinary.com/brandhub/image/upload/v1611763981/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Clos_Du_Bois_qtqin6.png",
"Estancia": "https://res.cloudinary.com/brandhub/image/upload/v1611764033/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Estancia_jm86dq.png",
"Franciscan": "https://res.cloudinary.com/brandhub/image/upload/v1611764043/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Franciscan_ebezxc.png",
"Mark West": "https://res.cloudinary.com/brandhub/image/upload/v1611764124/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Mark_West_jd55d6.png",
"Alamos": "https://res.cloudinary.com/brandhub/image/upload/v1611763877/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Alamos_dmynsl.png",
"Andre": "https://res.cloudinary.com/brandhub/image/upload/v1611763895/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Andre_iizllq.png",
"Apothic": "https://res.cloudinary.com/brandhub/image/upload/v1611763912/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Apothic_yyolok.png",
"E&J VSOP Brandy": "https://res.cloudinary.com/brandhub/image/upload/v1611764016/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_E_J_VSOP_Brandy_psygws.png",
"E&J XO Brandy": "https://res.cloudinary.com/brandhub/image/upload/v1611764020/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_E_J_XO_Brandy_tx4q8h.png",
"E&J VS Brandy": "https://res.cloudinary.com/brandhub/image/upload/v1611764013/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_E_J_VS_Brandy_ffm19p.png",
"Barefoot Bubbly": "https://res.cloudinary.com/brandhub/image/upload/v1611763939/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Barefoot_Bubbly_mk0xsu.png",
"Barefoot": "https://res.cloudinary.com/brandhub/image/upload/v1611763948/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Barefoot_vc7vg8.png",
"Barefoot Cellars Box": "https://res.cloudinary.com/brandhub/image/upload/v1611763948/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Barefoot_vc7vg8.png",
"Barefoot Spritzers": "https://res.cloudinary.com/brandhub/image/upload/v1611763945/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Barefoot_Spritzers_iseiq1.png",
"Barefoot Seltzer": "https://res.cloudinary.com/brandhub/image/upload/v1611763942/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Barefoot_Seltzer_v4fqzf.png",
"Ballatore": "https://res.cloudinary.com/brandhub/image/upload/v1611763936/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Ballatore_ecwgcx.png",
"Bartles & Jaymes": "https://res.cloudinary.com/brandhub/image/upload/v1611763954/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Bear_Flag_gd6ej9.png",
"Rancho Zabaco": "https://res.cloudinary.com/brandhub/image/upload/v1611764172/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Rancho_Zabaco_ct6jav.png",
"Brancaia": "https://res.cloudinary.com/brandhub/image/upload/v1611763966/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Brancaia_podsqo.png",
"Bridlewood": "https://res.cloudinary.com/brandhub/image/upload/v1611763969/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Bridlewood_th16lp.png",
"Bella Sera": "https://res.cloudinary.com/brandhub/image/upload/v1611763958/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Bella_Sera_hcqmh7.png",
"Carnivor": "https://res.cloudinary.com/brandhub/image/upload/v1611763975/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Carnivor_vkxzuk.png",
"Maso Canali": "https://res.cloudinary.com/brandhub/image/upload/v1611764130/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Maso_Canali_rdesfi.png",
"Columbia": "https://res.cloudinary.com/brandhub/image/upload/v1611763984/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Columbia_r1qkpn.png",
"Covey Run": "https://res.cloudinary.com/brandhub/image/upload/v1611763990/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Covey_Run_wgntwe.png",
"Carlo Rossi": "https://res.cloudinary.com/brandhub/image/upload/v1611763972/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Carlo_Rossi_vmm8eu.png",
"Carlo Rossi Box": "https://res.cloudinary.com/brandhub/image/upload/v1611763972/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Carlo_Rossi_vmm8eu.png",
"Da Vinci": "https://res.cloudinary.com/brandhub/image/upload/v1611763993/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Da_Vinci_oufqb9.png",
"Dancing Bull": "https://res.cloudinary.com/brandhub/image/upload/v1611763995/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Dancing_Bull_jgp6kw.png",
"Dark Horse Wine": "https://res.cloudinary.com/brandhub/image/upload/v1611763998/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Dark_Horse_Wine_cp9xxa.png",
"Dark Horse Sparkling": "https://res.cloudinary.com/brandhub/image/upload/v1611763998/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Dark_Horse_Wine_cp9xxa.png",
"Dark Horse Box": "https://res.cloudinary.com/brandhub/image/upload/v1611763998/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Dark_Horse_Wine_cp9xxa.png",
"Don Miguel Gascon": "https://res.cloudinary.com/brandhub/image/upload/v1611764010/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Don_Miguel_Gascon_nltjlj.png",
"Don Fulano": "https://res.cloudinary.com/brandhub/image/upload/v1611764008/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Don_Fulano_jdgwf2.png",
"Ecco Domani": "https://res.cloudinary.com/brandhub/image/upload/v1611764023/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Ecco_Domani_j1jozy.png",
"Gallo Family Vineyards": "https://res.cloudinary.com/brandhub/image/upload/v1611764056/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Gallo_Family_Vineyards_ashxms.png",
"Edna Valley Vineyard": "https://res.cloudinary.com/brandhub/image/upload/v1611764027/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Edna_Valley_Vineyard_bhsf9e.png",
"Familia Camarena": "https://res.cloudinary.com/brandhub/image/upload/v1611764036/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Familia_Camarena_ognosx.png",
"Lo-Fi Aperitifs": "https://res.cloudinary.com/brandhub/image/upload/v1611764107/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Lo-Fi_Aperitifs_lpkfn5.png",
"Livingston Cellars": "https://res.cloudinary.com/brandhub/image/upload/v1611764103/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Livingston_Cellars_ohz5ei.png",
"Gallo Signature Series": "https://res.cloudinary.com/brandhub/image/upload/v1611764059/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Gallo_Signature_Series_erzhrc.png",
"Ghost Pines": "https://res.cloudinary.com/brandhub/image/upload/v1611764068/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_High_Noon_dzhqgo.png",
"High Noon": "https://res.cloudinary.com/brandhub/image/upload/v1611764068/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_High_Noon_dzhqgo.png",
"High Noon Beverage": "https://res.cloudinary.com/brandhub/image/upload/v1611764068/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_High_Noon_dzhqgo.png",
"Pahlmeyer": "https://res.cloudinary.com/brandhub/image/upload/v1611764154/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Pahlmeyer_mvw6gw.png",
"The Dalmore": "https://res.cloudinary.com/brandhub/image/upload/v1611764208/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_The_Dalmore_tazhby.png",
"John Barr": "https://res.cloudinary.com/brandhub/image/upload/v1611764079/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_John_Barr_ls2wa8.png",
"Jura": "https://res.cloudinary.com/brandhub/image/upload/v1611764081/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Jura_md7gbz.png",
"Kingdom": "https://res.cloudinary.com/brandhub/image/upload/v1611764084/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Kingdom_lc5wro.png",
"Thunderbird": "https://res.cloudinary.com/brandhub/image/upload/v1611764217/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Thunderbird_pytjke.png",
"Shackleton": "https://res.cloudinary.com/brandhub/image/upload/v1611764189/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Shackleton_fliucz.png",
"Bear Flag": "https://res.cloudinary.com/brandhub/image/upload/v1611763954/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Bear_Flag_gd6ej9.png",
"J Vineyards & Winery": "https://res.cloudinary.com/brandhub/image/upload/v1611764072/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_J_Vineyards_Winery_zaxgim.png",
"J Vineyards Sparkling": "https://res.cloudinary.com/brandhub/image/upload/v1611764072/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_J_Vineyards_Winery_zaxgim.png",
"La Marca": "https://res.cloudinary.com/brandhub/image/upload/v1611764087/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_La_Marca_cueuq3.png",
"Las Rocas": "https://res.cloudinary.com/brandhub/image/upload/v1611764093/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Las_Rocas_qcqhiq.png",
"Liberty Creek": "https://res.cloudinary.com/brandhub/image/upload/v1611764099/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Liberty_Creek_t1cjfe.png",
"Leftie": "https://res.cloudinary.com/brandhub/image/upload/v1611764096/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Leftie_u6dfeb.png",
"Louis M Martini": "https://res.cloudinary.com/brandhub/image/upload/v1611764112/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Louis_M_Martini_liydv4.png",
"Locations": "https://res.cloudinary.com/brandhub/image/upload/v1611764110/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Locations_natcuu.png",
"Sunseeker": "https://res.cloudinary.com/brandhub/image/upload/v1611764204/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Talbott_Vineyards_no9wvt.png",
"Steamsmith": "https://res.cloudinary.com/brandhub/image/upload/v1611764195/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Steamsmith_kf1hqs.png",
"Diplomatico": "https://res.cloudinary.com/brandhub/image/upload/v1611764005/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Diplomatico_bcbx53.png",
"Laguna": "https://res.cloudinary.com/brandhub/image/upload/v1611764090/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Laguna_kanzur.png",
"Mother": "https://res.cloudinary.com/brandhub/image/upload/v1611764140/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Mother_ar2dk2.png",
"Athena": "https://res.cloudinary.com/brandhub/image/upload/v1611763932/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Athena_npjd9d.png",
"MacMurray Estate Vineyards": "https://res.cloudinary.com/brandhub/image/upload/v1611764114/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_MacMurray_Estate_Vineyards_x4mc81.png",
"Martin Codax": "https://res.cloudinary.com/brandhub/image/upload/v1611764127/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Martin_Codax_zuvsft.png",
"Arch Rival": "https://res.cloudinary.com/brandhub/image/upload/v1611763925/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Arch_Rival_xrx093.png",
"Amaro Montenegro": "https://res.cloudinary.com/brandhub/image/upload/v1611763886/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Amaro_Montenegro_frbytu.png",
"Vecchia Romagna Brandy": "https://res.cloudinary.com/brandhub/image/upload/v1611764230/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Vecchia_Romagna_Brandy_l8wvkf.png",
"Amelia Brut Rose": "https://res.cloudinary.com/brandhub/image/upload/v1611763890/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Amelia_Brut_Rose_wtgujj.png",
"Ember": "https://res.cloudinary.com/brandhub/image/upload/v1611764031/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Ember_tr3dmp.png",
"Madria Sangria": "https://res.cloudinary.com/brandhub/image/upload/v1611764118/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Madria_Sangria_fe4yra.png",
"Mirassou": "https://res.cloudinary.com/brandhub/image/upload/v1611764137/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Mirassou_o26fjv.png",
"New Amsterdam Vodka": "https://res.cloudinary.com/brandhub/image/upload/v1611764147/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_New_Amsterdam_Vodka_jhvac3.png",
"Prophecy": "https://res.cloudinary.com/brandhub/image/upload/v1611764169/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Prophecy_kah9ek.png",
"Peter Vella": "https://res.cloudinary.com/brandhub/image/upload/v1611764166/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Peter_Vella_g85rsz.png",
"RumHaven": "https://res.cloudinary.com/brandhub/image/upload/v1611764181/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_RumHaven_utehbg.png",
"Redwood Creek": "https://res.cloudinary.com/brandhub/image/upload/v1611764178/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Redwood_Creek_iacgad.png",
"Saint Clair Family Estate": "https://res.cloudinary.com/brandhub/image/upload/v1611764183/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Saint_Clair_Family_Estate_rvhx0o.png",
"Fleur de Mer": "https://res.cloudinary.com/brandhub/image/upload/v1611764040/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Fleur_de_Mer_jrrs1f.png",
"Talbott Vineyards": "https://res.cloudinary.com/brandhub/image/upload/v1611764204/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Talbott_Vineyards_no9wvt.png",
"Thrive": "https://res.cloudinary.com/brandhub/image/upload/v1611764215/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Thrive_fivtni.png",
"Tott's": "https://res.cloudinary.com/brandhub/image/upload/v1611764223/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Tott_s_syuwcb.png",
"William Hill": "https://res.cloudinary.com/brandhub/image/upload/v1611764239/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_William_Hill_oggjvr.png",
"Mia Dolcea": "https://res.cloudinary.com/brandhub/image/upload/v1611764134/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Mia_Dolcea_kbcpij.png",
"Select Aperitivo": "https://res.cloudinary.com/brandhub/image/upload/v1611764186/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Select_Aperitivo_k6xb9z.png",
"Abandon": "https://res.cloudinary.com/brandhub/image/upload/v1611763846/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Abandon_foxggh.png",
"Gather & Grace": "https://res.cloudinary.com/brandhub/image/upload/v1611764062/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Gather_Grace_ntztk1.png",
"Department 66": "https://res.cloudinary.com/brandhub/image/upload/v1611764002/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Department_66_ke6yrb.png",
"Maison No. 9": "https://res.cloudinary.com/brandhub/image/upload/v1611764121/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Maison_No._9_cijb9v.png",
"New Amsterdam Gin": "https://res.cloudinary.com/brandhub/image/upload/v1611764144/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_New_Amsterdam_Gin_uw7wwx.png",
"Orin Swift": "https://res.cloudinary.com/brandhub/image/upload/v1611764150/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Orin_Swift_nhfy2v.png",
"Jayson": "https://res.cloudinary.com/brandhub/image/upload/v1611764075/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Jayson_snjfo2.png",
"Frei Brothers": "https://res.cloudinary.com/brandhub/image/upload/v1611764046/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Frei_Brothers_mschhh.png",
"Red Rock Winery": "https://res.cloudinary.com/brandhub/image/upload/v1611764175/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Red_Rock_Winery_fo668b.png",
"Starborough": "https://res.cloudinary.com/brandhub/image/upload/v1611764192/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Starborough_wga8ob.png",
"Storypoint": "https://res.cloudinary.com/brandhub/image/upload/v1611764197/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Storypoint_jndo1w.png",
"Chateau Souverain": "https://res.cloudinary.com/brandhub/image/upload/v1611763978/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Chateau_Souverain_pomlzq.png",
"Tisdale": "https://res.cloudinary.com/brandhub/image/upload/v1611764220/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Tisdale_zciher.png",
"Turning Leaf": "https://res.cloudinary.com/brandhub/image/upload/v1611764226/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Turning_Leaf_l5fpbd.png",
"The Naked Grape": "https://res.cloudinary.com/brandhub/image/upload/v1611764211/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_The_Naked_Grape_yvfonk.png",
"The Naked Grape Box": "https://res.cloudinary.com/brandhub/image/upload/v1611764211/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_The_Naked_Grape_yvfonk.png",
"Vin Vault": "https://res.cloudinary.com/brandhub/image/upload/v1611764233/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Vin_Vault_k7kwfv.png",
"Whitehaven": "https://res.cloudinary.com/brandhub/image/upload/v1611764236/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Whitehaven_aa65lm.png",
// keys missing from API
"Paul Masson Winery": "https://res.cloudinary.com/brandhub/image/upload/v1611764163/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Paul_Masson_Dessrts_puu5v4.png",
"Paul Masson Brandy": "https://res.cloudinary.com/brandhub/image/upload/v1611764160/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Paul_Masson_Brandy_fbwvqf.png",
"Cook's California Champagne" : "https://res.cloudinary.com/brandhub/image/upload/v1611763987/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Cooks_umnxvy.png",
"Black Box Premium Spirits": "https://res.cloudinary.com/brandhub/image/upload/v1611763961/prod/BrandLogos/GALLO.2101.RTA_2021_LOGOS_WORKING_118471_Black_Box_Spirits_kbuxcj.png",
};